@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=New+Amsterdam&family=Playwrite+SK:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Lilita+One&family=New+Amsterdam&family=Playwrite+SK:wght@100..400&display=swap');

.lilita-one-regular {
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.new-amsterdam-regular {
  font-family: "New Amsterdam", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.header {
  background-color: #f8f9fa;
  padding: 1rem;
}

.navbar-brand {
  font-size: 1.5rem;
  color: #000;
}

.nav-link {
  font-size: 1rem;
  color: #333;

  &:hover {
    color: #007bff;
  }
}

.btn-primary {
  background-color: #7f00ff;
  border-color: #7f00ff;
}

.btn-primary:hover {
  background-color: #33255c;
  border-color: #33255c;
}

/* Services Section */
.service img {
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* About Section */

.about-section{

  min-height: 40vh;
}

.about-section h2, .about-section p {
  filter: drop-shadow(1px 1px 1px #ffffff);
}

.services-page{
  background: #e3e3fc !important;
}

.services-cards{
  background-color: #f0f0fd;
  border: 1px solid #2b2046;
}
.services-cards:hover{
  box-shadow: 1px 1px 3px #9a8dec;
  transform: scale(102%);
}

/* App.css */

/* Footer Styles */
.footer {
  background-color: #7f00ff;
}

.footer .social-icons i {
  font-size: 1.5rem;
}

.footer .social-icons a {
  transition: color 0.3s;
}

.footer .social-icons a:hover {
  color: #b3b3b3;
}

/* App.css */


.hero-section-head {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.3);
}

.service-header{
  background: url('../assests/ServiceBg.jpg') rgba(0, 0, 0, 0.4);
  background-size: cover;
  background-position: 85% 5%;
  background-blend-mode: multiply;
}

.service-header .container{
  backdrop-filter: blur(2px);
}

@media (min-width: 992px) {
  .col-lg-25 {
    flex: 0 0 20.88%;
    max-width: 20.88%;
  }
}


/* Service Card */
.service-card {
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 2px 2px 6px #270a44;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.service-card i {
  color: #7f00ff;
}


.contact-section {
  background-color: #f8f9fa;
}

.contact-section h2 {
  font-size: 2rem;
  font-weight: bold;
}

.contact-section a {
  text-decoration: none;
}

/* App.css */

.landing-content{

  /* background-color: #8EC5FC;
  background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%); */

  /* background: linear-gradient(-45deg, #c5a9cd, #859cb8, #94ccd2);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite; */

  /* background: #7f00ff;  fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #004e92, #7f00ff);  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #004e92, #7f00ff); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background: #7f00ff;

}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}



/* Hero Section */
.hero-section {

  min-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
  width: 100%;

}



.team-card {
  background-color: #FBF2F7;
  transition: transform 0.3s, box-shadow 0.3s;
}

.team-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* App.css */
  


@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero-content-btn:hover{
  color: #000000 !important;
}

/* Contact Form Section */
.contact-form-section {
  background-color: #f8f9fa;
}

.contact-form-section h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

.contact-form-section .form-group {
  margin-bottom: 1.5rem;
}

.contact-form-section .form-control {
  border-radius: 0.375rem;
}

.contact-form-section textarea {
  border-radius: 0.375rem;
}

.contact-form-section button {
  font-size: 1rem;
}

/* Map Container */
.map-container {
  border-radius: 0.375rem;
  overflow: hidden;
}

.contact-info {
  font-size: 1rem;
  color: #7f00ff;
}

.contact-info i {
  color: #7f00ff;
  margin-right: 0.5rem;
}


@media (max-width: 586px){
  .lg-nav-logo{
    display: none;
  }
  .logo-text{
    font-size: 6vw;
  }
  .sm-nav-logo{
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .sm-nav-content{
    display: inline-block !important;
  }
  .custom-nav-links{
    display: none !important;
  }
  .custom-cta{
    display: none !important;
  }
  .carousel-img{
    width: 100%;
    height: 150px !important;
    object-fit: contain;
  }
}

@media (min-width: 586px) and (max-width: 1120px){
  .lg-nav-logo{
    display: none;
  }
  .logo-text{
    font-size: 6vw;
  }
  .sm-nav-logo{
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .sm-nav-content{
    display: inline-block !important;
  }
  .custom-nav-links{
    display: none !important;
  }
  .custom-cta{
    display: none !important;
  }
  .carousel-img{
    width: 50%;
  }
}

@media (min-width: 1121px){
  .lg-nav-logo{
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .sm-nav-logo{
    display: none;
  }
  .sm-nav-content{
    display: none !important;
  }
  .custom-nav-links{
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .custom-cta{
    display: flex !important;
    align-items: center;
    justify-content: end;
  }
  .carousel-img{
    height: 200px;
  }
}




.feature-card {
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, #642cc6 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}

.icon-wrapper img{
  height: 60px;
  width: 60px;
}

.icon-wrapper {
  border: 1px solid #642cc6;
  position: relative;
  margin: auto;
  font-size: 30px;
  width: fit-content;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}
.feature-card:hover {
  background-position: 0 100% !important;
}
.feature-card:hover .icon-wrapper {
  background-color: #ffffff;
  color: #642cc6;
}
.feature-card:hover h3 {
  color: #ffffff;
}
.feature-card:hover p {
  color: #f0f0f0;
}

.landing-content{
  /* box-shadow:  */
  text-shadow: 4px 4px 12px #1e0d3c;
}
.landing-btn{
  box-shadow: 2px 2px 12px #1e0d3c;
}